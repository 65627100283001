import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Col, Row } from "react-bootstrap"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"
import data_yaml from "../data/project_data/data.yaml"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

class IndexPage extends React.Component{
  constructor(data){
    super(data);
    //console.log(data);
    this.items = [];
    for(let item of data.data.allFile.nodes){
      let name = item.name;
      if(data_yaml.all_projects[name]){
        this.items.push({image: getImage(item.childImageSharp), title: data_yaml.all_projects[name].all_projects_title.toUpperCase(), link: data_yaml.all_projects[name].link})
      }
    }
    //console.log(this.items);
  }
  componentDidMount(){
    //console.log("AAAA" + __dirname);
  }
  render(){
    return <Layout pageInfo={{ pageName: "index", pageTitle: "HOME" }}>
    <SEO title="Home" keywords={[`DAVID L. WALDRON / ARCHITECT`]} />
    <div className="home_page">
      <Row noGutters>
        <Col md={6} className="home_image_1">
          <Link to={this.items[0].link}>
            <GatsbyImage image={this.items[0].image} alt="" />
            <div className="overlay"></div>
            <div className="info">
              <h1>{this.items[0].title}</h1>
            </div>
          </Link>
        </Col>
        <Col md={6} className="home_image_2">
          <Link to={this.items[1].link}>
          <GatsbyImage image={this.items[1].image} alt="" />
            <div className="overlay"></div>
           <div className="info">
              <h1>{this.items[1].title}</h1>
            </div>
          </Link>
        </Col>
      </Row>
      <Row noGutters>
        <Col md={6} className="home_image_3">
          <Link to={this.items[2].link}>
          <GatsbyImage image={this.items[2].image} alt="" />
            <div className="overlay"></div>
            <div className="info">
              <h1>{this.items[2].title}</h1>
            </div>
          </Link>
        </Col>
        <Col md={6} className="home_image_4">
          <Link to={this.items[3].link}>
          <GatsbyImage image={this.items[3].image} alt="" />
            <div className="overlay"></div>
            <div className="info">
              <h1>{this.items[3].title}</h1>
            </div>
          </Link>
        </Col>
      </Row>
    </div>
  </Layout>
  }
}
export default IndexPage

export const Data = graphql`
  query{
    allFile(filter: {sourceInstanceName: {eq: "home_page"}}, sort: {fields: [name], order: ASC}) {
      nodes {
        id
        name
        relativePath
        sourceInstanceName
        relativeDirectory
        childImageSharp {
          gatsbyImageData(width:950)
        }
      }
    }
  }
`